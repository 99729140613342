import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginApi from "../api/LoginApi";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);

  const navigate = useNavigate();
    
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await LoginApi(username, password);
      if (response.status === 200) {
        setLoggedIn(true);
      } else {
        console.error('Login failed:');
      }
    } catch (error) {
      console.log('failed to login', error);
    }

  };

  if (loggedIn) {
    // Redirect to admin panel if logged in
    navigate('/api/admin/management/');
  }

  
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="sm:bg-white rounded-3xl shadow-xl overflow-hidden w-full max-w-4xl">
        <div className="md:flex">
          <div className="hidden md:block w-1/2 bg-cover" style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1713145872144-351db3748385?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")' }}></div>
          <div className="w-full md:w-1/2 p-8">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-white sm:text-gray-900 mb-8">ESK'ye HOŞGELDİNİZ</h2>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Email/Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
              <div className="flex justify-between items-center mb-6">
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="ml-2 text-sm text-gray-600">Remember password</span>
                </label>
                <Link to="#" className="text-sm text-blue-600 hover:underline">Forgot password?</Link>
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600"
              >
                Log In
              </button>
              <p className="mt-4 text-sm text-center text-gray-600">
                Don't have an account? <Link to="/signup" className="text-blue-600 hover:underline">Sign up</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
