import axios from 'axios';

const SearchPeople = async (params) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
        const response = await axios.post(
            `${apiUrl}/api/search/`,
            { params },
        )
  
        return await response.data;
        
    } catch (error) {
        console.log(error);
    }
}

export default SearchPeople;