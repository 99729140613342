import React from 'react';
// import Category from './Category';
// import Popularity from './Popularity';
import EskContainer from './EskContainer';

const EskSection = ({data}) => {
    return (
        <>
            <p className="text-white text-lg sm:text-2xl font-medium mb-3">
                Ünlü Eskortlar
            </p>
            <hr className='my-6 mx-12 h-[1px] border-t-0 bg-gray-500'/>

            <div className='text-white sm:flex sm:justify-center'>
                <div className='hidden sm:block py-6 px-3'>
                    {/* <div className='py-6 w-40 bg-deepBlue
                    rounded-3xl mb-6'>
                        <p className='text-xl font-medium mb-3'>Kategoriler</p>
                        <Category/>
                    </div> */}
                    {/* <div className='py-6 bg-deepBlue rounded-3xl'>
                        <p className='text-xl font-medium'>Popülerlik</p>
                        <Popularity/>
                    </div> */}
                </div>
                <div className=''>
                    <EskContainer data={data}/>
                </div>
            </div>
        </>
    );
}

export default EskSection;