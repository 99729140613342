import HomeConetent from '../components/home/HomeContent';
import NavBar from '../components/NavBar';
// import whatsapp from '../social/whatsapp.png';
import { Link } from 'react-router-dom';
import telegram from '../social/Telegram.svg.webp';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
} from "@material-tailwind/react";

const HomePage = ({ data }) => {
    const phone = 'https://t.me/ESK_HIZMET';
    return (
        <div className="bg-bgColor max-w-screen-2xl mx-auto relative">
            <NavBar data={data} />
            <HomeConetent data={data} />
            <div className='fixed bottom-20 right-5'>
                <Popover
                    animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0, y: 25 },
                    }}
                >
                    <PopoverHandler>
                        <Button className='p-0 rounded-full'><img src={telegram} alt="" className='w-12' /></Button>
                    </PopoverHandler>
                    <PopoverContent className='w-2/3 sm:w-1/5 md:1/5'>
                        <div>
                            <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700 flex justify-center">
                            <Link to={phone}>
                                <img src={telegram} alt="" className='w-10' />
                            </Link>
                            </div>
                            <div className="px-3 py-2 text-xs sm:text-sm text-black">
                                <p>Bilgilerinizi listelemek, reklam vermek veya iletişim bilgilerinizi web sitemize eklemek istiyorsanız, lütfen bizimle iletişime geçin.</p>
                            </div>
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        </div>

    );
}

export default HomePage;