import SideBar from "./SideBar";
import PhotoSection from "./PhotoSection";

const ProfileContainer = ({data}) => {

    return (
        <div className='flex flex-col-reverse sm:flex-row text-white sm:flex sm:justify-start'>
            <div className='sm:block py-6 px-3'>
                <div className='sm:w-40
                rounded-3xl mb-6'>
                    <SideBar data={data} />
                </div>
            </div>
            <div>
                <PhotoSection data={data} />
            </div>
        </div>
    );
}

export default ProfileContainer;