import React, { useState } from 'react';
import AddPerson from '../api/AddPerson';

const AdminPannel = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [age, setAge] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [cup, setCup] = useState('');
    const [address, setAddress] = useState('');
    const [vip, setVip] = useState('');
    const [pay, setPay] = useState('');
    const [desc, setDesc] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const cupCap = cup.toUpperCase();

        const formData = new FormData();

        for (let i = 0; i < selectedImages.length; i++) {
            formData.append('escort_images', selectedImages[i]);
        }
    
        formData.append('name', name);
        formData.append('contact', phone);
        formData.append('age', age);
        formData.append('height', height);
        formData.append('weight', weight);
        formData.append('cup', cupCap);
        formData.append('address', address);
        formData.append('is_vip', vip);
        formData.append('is_paid', pay);
        formData.append('description', desc);
        
        AddPerson(formData);

        setName('');
        setAge('');
        setCup('');
        setDesc('');
        setPay('');
        setHeight('');
        setPhone('');
        setVip('');
        setWeight('');
        setAddress('');

    };

    const handleImageChange = (event) => {
        // Get the selected images
        const files = Array.from(event.target.files);
        setSelectedImages(files);
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-900">
                <div className="md:bg-deepBlue text-black sm:text-black rounded-3xl shadow-xl overflow-hidden w-full max-w-6xl">
                    <div className="sm:flex">
                    <div className="hidden md:block w-1/2 bg-cover" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1713145872144-351db3748385?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}></div>
                    <div className="w-full md:w-1/2 p-8">
                        <div className="text-center">
                        <h2 className="text-4xl font-bold text-white mb-8">ESK'ye HOŞGELDİNİZ Admin Pannel</h2>
                        </div>
                        <form className="space-y-6 text-black" id="addPeople" onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor="name" className='mb-3 text-white'>Name</label>
                                <input
                                    id="name"
                                    name="name"
                                    required={true}
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                                </div>
                            <div className="mb-3">
                                <label htmlFor="number" className="text-white">Whatsapp No.</label>
                                <input
                                    id="number"
                                    name="phone"
                                    required={true}
                                    type="number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Whatsapp No.(eliminate the space and plus sign and dash)"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="age" className="text-white">Age</label>
                                <input
                                    id="age"
                                    name="age"
                                    required={true}
                                    type="number"
                                    placeholder="Age"
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="height" className="text-white">Height</label>
                                <input
                                    id="height"
                                    name="height"
                                    required={true}
                                    type="number"
                                    placeholder="Height"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-3">
                            <label htmlFor="weight" className="text-white">Weight</label>
                                <input
                                    id="weight"
                                    name="weight"
                                    required={true}
                                    type="number"
                                    placeholder="Weight"
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="cup" className="text-white">Cup</label>
                                <input
                                    id="cup"
                                    name="cup"
                                    required={true}
                                    type="text"
                                    placeholder="Cup"
                                    value={cup}
                                    onChange={(e) => setCup(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address" className="text-white">Address</label>
                                <textarea
                                    id="address"
                                    name="address"
                                    required={true}
                                    type="text"
                                    placeholder="Address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="payment_status" className="text-white">Payment Status</label>
                                <input
                                    id="payment_pending"
                                    name="payment_status"
                                    type="radio"
                                    value={0}
                                    placeholder="Payment Pending"
                                    onChange={(e) => setPay(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                                <label htmlFor="payment_pending" className="text-white">Not Paid</label>
                                <input
                                    id="payment_completed"
                                    name="payment_status"
                                    type="radio"
                                    value={1}
                                    onChange={(e) => setPay(e.target.value)}
                                    placeholder="Payment Completed"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                                <label htmlFor="payment_completed" className="text-white">Paid</label>
                            </div>
                            <div className='mb-3'>
                            <label htmlFor="vip" className="text-white">Vip Status</label>
                                <input
                                    id="is_vip"
                                    name="vip"
                                    type="radio"
                                    value={1}
                                    onChange={(e) => setVip(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                                <label htmlFor="is_vip" className="text-white">Vip</label>
                                <input
                                    id="normal"
                                    name="vip"
                                    type="radio"
                                    value={0}
                                    onChange={(e) => setVip(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                                <label htmlFor="normal" className="text-white">Not Vip</label>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="text-white">Description</label>
                                <textarea
                                    id="description"
                                    name="desc"
                                    type="text"
                                    placeholder="Description"
                                    value={desc}
                                    onChange={(e) => setDesc(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            {/* image */}
                            <div className="mb-3">
                                <label
                                    htmlFor="images"
                                    className="mb-2 inline-block text-white"
                                >
                                Choose Images
                                </label>
                                <input
                                name="images"
                                    className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                                    type="file"
                                    id="images"
                                    onChange={handleImageChange}
                                    multiple
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-3 bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600"
                            >
                                Add
                            </button>
                        
                        </form>
                    </div>
                    </div>
                </div>
            </div>
    );
}

export default AdminPannel;