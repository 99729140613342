const Personal = ({data}) => {
  const profile = data.escort_images.length > 0 ? (
    <img
                className="h-12 w-12 rounded-full object-cover object-center"
                src={data.escort_images[0].image}
                alt={data.escort_images[0]}
                />
  ) : <p></p>;
  return (
    <div className="container sm:rounded-2xl text-white sm:w-52 sm:bg-deepBlue sm:p-4 sm:hover:bg-blue-500 sm:hover:shadow-lg sm:hover:shadow-cyan-500/50">
      <div className="flex justify-between sm:block">
        <div>
            <div className="avatar flex justify-center">
                {profile}
                {/* <div className="h-12 w-12 rounded-full bg object-cover object-center" style={{backgroundImage: `url(${data.escort_images[0].image})`}}>

                </div> */}
            </div>
            <div className="name my-2 mb-4 text-xl font-semibold">
                <p>{data.name}</p>
            </div>
        </div>
        <div className="count flex justify-center flex-col sm:mb-3">
            <span className="font-medium">{data.escort_images.length}</span>
            <span>Photo</span>
        </div>
      </div>
      <div className="des">
        <p className="text-justify sm:text-left text-sm">
          {data.description}
        </p>
      </div>
    </div>
  );
};

export default Personal;
