import Contact from "./Contact";
import Description from "./Description";
import Personal from "./Personal";

const SideBar = ({data}) => {
    return (
        <div className="flex flex-col-reverse sm:flex-col">
            <div>
                <Personal data={data} />
                <Description data={data} />
            </div>
            <Contact data={data} />
        </div>
    );
}

export default SideBar;