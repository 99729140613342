import { Link } from "react-router-dom";

const EskContainer = ({data}) => {

    if (!data) {
        return <div className="text-white">No data available!</div>;
    }

    const esks = data.map(e => 
        e.is_vip === false ?
        <Link to={`/profile/${e.id}`} key={e.id}>
        <li  className='group sm:w-44 bg-gradient-to-t from-deepBlue to-blue-700 m-1 rounded-3xl'>
            <div className="w-full h-full transition duration-300 ease-in-out group-hover:bg-indigo-500 group-hover:shadow-lg rounded-3xl">
            {e.escort_images && e.escort_images.length > 0 && (
                <div className='h-56 bg-cover rounded-t-3xl relative' style={{backgroundImage: `url(${e.escort_images[0].image})`}}>
                    <div className='absolute bottom-0 bg-gray-300 opacity-80 rounded-xl py-1 px-2 text-xs text-black'>
                        <p>{e.name}</p>
                    </div>
                    <div className='absolute flex bottom-0 right-0 bg-gray-300 opacity-80 rounded-xl p-1 text-xs text-black'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                    </svg>
                        <p>{e.escort_images.length}</p>
                    </div>
                </div>
                )}
                <div className="flex justify-between text-sm mx-2">
                    <p className="my-2 text-white">Yaş: {e.age}</p>
                </div>
            </div>
        </li>
        </Link> : null
    );

    return (
        <ul className='grid grid-cols-2 sm:flex sm:flex-wrap'>
            {esks}
        </ul>
    );
}

export default EskContainer;