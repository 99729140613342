
const Description = ({data}) => {

    return (
        <div className="text-white sm:w-52 rounded-2xl bg-deepBlue p-4 mt-4 mb-3 hover:bg-pink-500 hover:shadow-lg hover:shadow-cyan-500/50 ">
            <p className="font-semibold mb-3">
            vücut bilgisi
            </p>
            <div className="flex flex-col text-left">
                <div className="flex justify-between">
                    <span>
                        <strong>Yaş:</strong>
                    </span>
                    <span>
                        {data.age} yaşında
                    </span>
                </div>
                <div className="flex justify-between">
                    <span>
                        <strong>Boyu:</strong>
                    </span>
                    <span>
                        {data.height} cm
                    </span>
                </div>
                <div className="flex justify-between">
                    <span>
                        <strong>Kilo:</strong>
                    </span>
                    <span>
                        {data.weight} kg
                    </span>
                </div>
                <div className="flex justify-between">
                    <span>
                        <strong>Göğüs ölçüleri:</strong>
                    </span>
                    <span>
                        {data.cup}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Description;