import { Link } from 'react-router-dom';
import whatsapp from '../../social/whatsapp.png';

const Contact = ({data}) => {
    const text = "Merhaba,%20Esk%20web%20sitesinden%20aldım%20numaranızı,%20musaitmisiniz?";
    return (
        <div className="text-white flex justify-between sm:w-52 rounded-2xl sm:bg-deepBlue p-4 mt-4 mb-6 sm:mb-3 shadow-lg shadow-cyan-500/50 sm:hover:bg-purple-500 sm:hover:shadow-lg hover:shadow-cyan-500/50">
            <div>
                <p className="font-semibold text-center sm:mb-3">
                    Benimle irtibata geçin
                </p>
                <p className='text-xs mt-3'>
                    {data.address}
                </p>
            </div>
            <div className='sm:flex sm:justify-center'>
                {
                    data.is_paid === true ? 
                    <Link to={`https://wa.me/${data.contact}?text=${text}`}>
                        <img src={whatsapp} className='w-12 sm:w-16 sm:hover:scale-125' alt={whatsapp} />
                    </Link> : <p></p>
                }
                
            </div>
        </div>
    );
}

export default Contact;