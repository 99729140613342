import React, { useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from '../../utils/Modal';

const PhotoSection = ({data}) => {

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
      };
    
      const handleCloseModal = () => {
        setSelectedImage(null);
      };

    const esks = data.escort_images.length > 0 ? data.escort_images.map((e, index) => (
        <div className='group sm:w-44 sm:m-1 rounded-3xl' key={index}>
            <div className="w-full h-full transition duration-300 ease-in-out group-hover:shadow-lg rounded-3xl">
                <img src={e.image} className='sm:rounded-xl transition duration-300 ease-in-out sm:hover:scale-150' onClick={() => handleImageClick(e.image)} alt="" />
            </div>
        </div>
    )) : <p></p>;

    // Settings for the slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 640, 
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };

    return (
        <div>
            <div className="sm:hidden">
                <Slider {...settings} dotsClass="slick-dots green-dots">
                    {esks}
                </Slider>
            </div>


            <div className="hidden sm:flex sm:flex-wrap sm:ml-20">
                {esks}
                {selectedImage && (
                    <Modal imageUrl={selectedImage} onClose={handleCloseModal} />
                )}
            </div>

        </div>
    );
}

export default PhotoSection;