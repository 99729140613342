import axios from 'axios';

const GetData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
        const response = await axios.get(
            `${apiUrl}/api/`,
        );
        if (response.status === 200) {
            const data = await response.data;
            return data;
        } else {

            throw new Error("failed to fetch data");
        }
        
    } catch (e) {
        return [];
    }
}

export default GetData;