import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SearchPeople from '../api/SearchPeople';
import NavBar from './NavBar';
import EskContainer from '../components/home/EskContainer';

const SearchResult = () => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query');

    useEffect(() => {
        const fetchResults = async () => {
          if (query) {
            try {
              const response = await SearchPeople(query);
              setResults(response);
            } catch (error) {
              console.error('Error fetching search results:', error);
            } finally {
              setLoading(false);
            }
          }
        };
    
        fetchResults();
    }, [query]);

    if (loading) {
        return <div className='text-white'>Loading...</div>;
    }

    return (
        <div>
            <NavBar/>
            <h1 className='text-white mt-5 mb-5'>İçin arama sonuçları "{query}"</h1>
                {results.length > 0 ? (
                    <div className='mx-6'>
                        <EskContainer data={results} />
                    </div> 
                ) : (
                    <div className='mt-96 pb-[500px] h-full'>
                        <p className='text-white'>Sonuç bulunamadı</p>
                    </div>
                )}
        </div>
    );
};
    
export default SearchResult;