import axios from 'axios';

const GetSingle = async (pk) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/esk/${pk}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default GetSingle;