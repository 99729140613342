import VipSection from "./VipSection";
import EskSection from "./EskSection";

const HomeConetent = ({data}) => {
    return (
        <div className="mx-3 pb-6">
            <VipSection data={data}/>
            <EskSection data={data}/>
        </div>
    );
}

export default HomeConetent;