import axios from 'axios';

const LoginApi = async (username, password) => {
    try {
      const response = await axios.post(
        '',
        {
          username,
          password
        }
      );

      if (response.status === 200) {
        const { access } = response.data;
        localStorage.setItem('token', access);
        return response.data;
      } else {
        console.log('failed to log in');
      }
    } catch (error) {
      console.log('failed to lof in');
    }
    
}

export default LoginApi;