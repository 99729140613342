// import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';


const Inavigation = [
  { name: 'Home', to: '/', current: true },
  { name: 'Blog', to: '/blog', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavBar = () => {

  const [isScrolled, setIsScrolled] = useState(false);
  const history = useNavigate();
  const navigate = useNavigate();
  const [navigation, setNavigation] = useState(Inavigation);

  const [query, setQuery] = useState('');

  const handleItemClick = (index) => {
    const updatedNavigation = navigation.map((item, i) => ({
      ...item,
      current: i === index,
    }));
    setNavigation(updatedNavigation);
  };

  const location = useLocation();

  const isHomePage = location.pathname === '/';
  const isSearchPage = location.pathname === '/search';

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setQuery('');
    navigate(`/search?query=${query}`);

  };

  return (
    <Disclosure as="nav" className={`w-full z-50 ${isScrolled ? 'fixed top-0 left-0 bg-bgColor shadow-md' : ''}`}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              {/* Back button */}
              {!isHomePage ? (
                <button
                  onClick={() => history('/')}
                  className="flex items-center pl-2 text-gray-400 focus:outline-none"
                >
                  <ArrowLeftIcon className="h-6 w-6 sm:hidden" aria-hidden="true" />
                  <span className="sr-only">Back</span>
                </button>
              ) : (
                <p className="text-white sm:hidden font-semibold text-xs text-wrap mt-3 w-24">EĞLENCENİN ADRESİ ESK</p>
              )}
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white mt-3">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <p className="hidden sm:block text-white font-bold sm:text-md">EĞLENCENİN ADRESİ ESK</p>
                </div>
                <div className="max-w-md mr-7 sm:mx-auto">
                  {/* Conditional rendering of the search form */}
                  {isHomePage || isSearchPage ? (
                    <form className="pt-1 relative mx-auto text-white" onSubmit={handleSubmit}>
                      <div className="pt-2 relative mx-auto text-white">
                        <input className="bg-gradient-to-r from-indigo-700 to-gray-900 h-10 px-5 rounded-3xl text-sm focus:outline-none"
                          type="search" name="search" placeholder="Search..." value={query} onChange={(e) => setQuery(e.target.value)} />
                        <button type="submit" className="absolute right-0 top-0 mt-4 mr-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                          </svg>
                        </button>
                      </div>
                    </form>
                  ) :
                    <p className='text-white my-3'>profil detayları</p>
                  }
                </div>

                <div className="hidden sm:ml-2 sm:block sm:my-2">
                  <div className="flex space-x-4">
                    {navigation.map((item, index) => (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        onClick={() => handleItemClick(index)}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}

                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>

                <div className='hidden sm:ml-2 sm:block sm:my-4 z-50'>
                  <div className='flex space-x-4'>
                    {/* language selection */}
                    <div className='text-white'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}  // Assuming item.href contains the route path
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default NavBar;