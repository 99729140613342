import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const VipSection = ({data}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };


    const vip = data.map(e => (
        e.is_vip === true ? 
            <Link to={`/profile/${e.id}`} key={e.id}>
            <div className="container block sm:flex sm:flex-wrap mx-auto text-yellow-50 sm:justify-center relative overflow-hidden w-10/12">
                <img src={e.escort_images[0].image} className="sm:mx-1 sm:h-96 sm:rounded-3xl" alt={e.escort_images[0].image} />
                <div className="absolute bottom-3 left-3 bg-gray-300 opacity-80 rounded-xl py-1 px-2 text-sm text-black">
                    {e.name}
                </div>
                <div className="absolute bottom-3 left-1/3 bg-gray-300 opacity-80 rounded-xl py-1 px-2 text-sm text-black">
                    {e.age} yaşında
                </div>
                <div className="absolute transform rotate-45 bg-red-700 text-center text-white font-semibold py-1 right-[-35px] top-[32px] w-[170px]">
                    <p className="text-md">VIP</p>
                </div>
                <div className='absolute flex bottom-3 right-3 bg-gray-300 opacity-80 rounded-xl p-1 text-xs text-black'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                    </svg>
                        <p>{e.escort_images.length}</p>
                </div>
            </div>
        </Link> 
         : null

    ));
    return (
        <>
            <p className="text-white text-lg sm:text-2xl font-medium mt-6">
                VIP Eskortlar
            </p>
            <hr className='my-3 mx-12 h-[1px] border-t-0 bg-gray-500'/>
            <div className="container block sm:flex mx-auto text-yellow-50 sm:justify-center my-6">
                <div className="sm:hidden">
                    <Slider {...settings} dotsClass="slick-dots green-dots" className="green-arrows">
                        {vip}
                    </Slider>
                </div>
                <div className="hidden sm:flex mx-auto text-yellow-50 sm:justify-center">
                    {vip}
                </div>
            </div>
        </>
    );
}

export default VipSection;