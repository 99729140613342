import axios from 'axios';

const AddPerson = async (formData) => {

  const token = localStorage.getItem('token');

  try {

    const response = await axios.post('/api/add/people/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
    });

    window.alert('Successfully add person!');
    return response.data;

  } catch (error) {

    window.alert('Error posting data:', error);
    throw error;
  }
};

export default AddPerson;

