import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import LoginPage from '../src/pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import { useState, useEffect } from 'react';
import GetData from './api/DataApi';
import AdminPannel from './pages/AdminPannel';
import SearchResult from './components/SearchResult';

function App() {

  const [data, setData] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const result = await GetData();
          setData(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);

  return (
    <div className="App bg-bgColor">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage data={data}/>}/>
        <Route path='/api/admin/login/' element={<LoginPage/>} />
        <Route path='/api/admin/management/' element={<AdminPannel />} />
        <Route path='profile/:id' element={<ProfilePage />} />
        <Route path="/search" element={<SearchResult />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
